import { useEffect } from 'react';

import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import { useGetUnpublishedRecordsQuery } from '@otello/api';
import { BellLine } from '@otello/assets';
import {
  getHasUnpublishedRecords,
  pages,
  rem,
  useToggle,
} from '@otello/helpers';
import { UNPUBLISHED_MODAL_OPENED, useAppSelector } from '@otello/store';
import { BREAKPOINTS, theme } from '@otello/theme';
import { ButtonBase, Drawer, ModalBase } from '@otello/ui';
import { useNavigate } from 'react-router-dom';

/** Модальное окно с уведомлением о несохраненных изменениях */
export const UnpublishedModal = () => {
  const isDesktop = useMediaQuery(theme.breakpoints.up(BREAKPOINTS.MD));

  const [isOpen, { open, close }] = useToggle(false);

  const active = useAppSelector((state) => state.hotel.active);

  const navigate = useNavigate();

  const { currentData } = useGetUnpublishedRecordsQuery(String(active?.id), {
    skip: !active?.id,
  });

  /** Переход на страницу "Доступность и Цены" */
  const handleNavigate = () => {
    navigate(pages.PRICES);

    handleCloseModal();
  };

  /** Закрытие модального окна */
  const handleCloseModal = () => {
    close();
  };

  useEffect(() => {
    if (!active?.id || !currentData) {
      return;
    }

    const storageData = localStorage.getItem(UNPUBLISHED_MODAL_OPENED);
    const storageParsedData = storageData ? JSON.parse(storageData) : {};

    /** Если есть неопубликованные изменения и модальное окно не показывалось,
     * то открываем модальное окно */
    if (
      storageParsedData[active?.id] !== 'true' &&
      getHasUnpublishedRecords(
        currentData.has_unpublished_records,
        currentData.code,
      )
    ) {
      open();
    }

    /** Вышеперечисленные проверки отработают только при новом входе в портал,
     * так как id отеля в любом случае записываем в хранилище */
    localStorage.setItem(
      `${UNPUBLISHED_MODAL_OPENED}`,
      JSON.stringify({
        ...storageParsedData,
        [String(active.id)]: 'true',
      }),
    );
  }, [active?.id, currentData, open]);

  const baseTitle = (
    <Box display="flex" alignItems="center">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        width={rem(40)}
        height={rem(40)}
        color="basic.red.90"
        mr={rem(8)}
      >
        <BellLine width={rem(40)} height={rem(40)} />
      </Box>

      <Typography variant="h2">
        Неопубликованные
        <br /> изменения
      </Typography>
    </Box>
  );

  const baseContent = (
    <Grid container mt={rem(24)}>
      <Grid item container gap={rem(24)}>
        <Grid item xs={12}>
          <Typography variant="body1">
            {isDesktop
              ? 'В разделе Доступность и Цены есть неопубликованные изменения. Нажмите Опубликовать и изменения станут доступны в Отелло.'
              : 'В разделе Цены есть неопубликованные изменения. Нажмите Опубликовать и изменения станут доступны в Отелло.'}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <ButtonBase
            data-cy="go_to_prices_button"
            size={isDesktop ? 'large' : 'medium'}
            onClick={handleNavigate}
          >
            {isDesktop
              ? 'Перейти в раздел Доступность и Цены'
              : 'Перейти в раздел Цены'}
          </ButtonBase>
        </Grid>
      </Grid>
    </Grid>
  );

  if (isDesktop) {
    return (
      <ModalBase
        closeButtonAttributeValue="unpublished_modal_close_button"
        title={baseTitle}
        open={isOpen}
        onClose={handleCloseModal}
        paperSx={{
          width: rem(360),
          minWidth: 'auto',
          maxWidth: '100%',
        }}
      >
        {baseContent}
      </ModalBase>
    );
  }

  return (
    <Drawer
      closeButtonAttributeValue="unpublished_modal_close_button"
      title={baseTitle}
      open={isOpen}
      onClose={handleCloseModal}
      isShowCloseIcon
      isShowBackButton={false}
      sx={{ pt: rem(24) }}
    >
      {baseContent}
    </Drawer>
  );
};
